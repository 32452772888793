function fetchJSON(filename, property) {
  //console.log("process.env.PUBLICURL: ", process.env.PUBLIC_URL);
  return fetch(`${process.env.PUBLIC_URL}/${filename}`)
    .then(res => res.json())
    .then(json => (window[property] = json));
}

const getConfig = () => fetchJSON('config.json', 'CONFIG');
//const getEng = () => fetchJSON('i18n/en.json', 'EN');
//const getIta = () => fetchJSON('i18n/it.json', 'IT');

const fetchAllConfig = () => Promise.all([getConfig()]);

export { fetchAllConfig };

