import React, { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { fetchAllConfig } from 'import-config';

fetchAllConfig().then(() => {

    const App = lazy(() => import('./App'));
    const container = document.getElementById('root');
    const root = createRoot(container);

  return root.render(
    <Suspense /*fallback={<Loading />}*/>
      <App />
    </Suspense>
  );

});
